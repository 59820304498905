import React from "react";
import styled from "styled-components";
import { videos } from "../data/portfolio-videos";
import { color } from "../theme/theme.utils";

const StyledYoutubeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 16px;
  gap: 24px;

  iframe {
    box-shadow: 0 0 9px 1px rgb(0 0 0 / 75%);
    border-radius: 10px;
    max-width: 800px;
    width: 100%;
    height: 500px;
  }
`;

const StyledVideoSubtitle = styled.p`
  color: ${color.grey};
  margin-top: -16px;
`;

const StyledVideoTitle = styled.h3`
  margin-top: 16px;
  color: ${color.black};
`;

const YoutubeVideo = () => {
  const videosToRender = videos.map(({ title, subtitle, url }, index) => (
    <>
      {title && (
        <StyledVideoTitle key={`${title}-title`}>{title}</StyledVideoTitle>
      )}
      <iframe
        key={`${title}-frame`}
        src={url}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title={`youtube-video__${index}`}
      />
      {subtitle && (
        <StyledVideoSubtitle key={`${title}-subtitle`}>
          {subtitle}
        </StyledVideoSubtitle>
      )}
    </>
  ));
  return <StyledYoutubeContainer>{videosToRender}</StyledYoutubeContainer>;
};

export default YoutubeVideo;
