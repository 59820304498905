import React from "react";
import styled from "styled-components";
import { SRLWrapper } from "simple-react-lightbox";

const StyledGalleryContainer = styled.div`
  padding: 16px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  a {
    padding: 0 1rem 1rem;
  }

  img {
    max-height: 250px;
  }
`;

const PortfolioGallery = ({ images = [] }) => {
  return (
    <SRLWrapper>
      <StyledGalleryContainer>
        {images.map((el) => (
          <a href={el.src}>
            <img
              src={el.src}
              srcSet={el.srcSet}
              sizes={el.sizes}
              alt={el.name}
            />
          </a>
        ))}
      </StyledGalleryContainer>
    </SRLWrapper>
  );
};

export default PortfolioGallery;
