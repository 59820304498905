import React from "react";
import styled from "styled-components";
import { color } from "../theme/theme.utils";

const StyledGalleryFiltersContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const StyledFilterElement = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 35px;
  margin: 8px 0;
  border: solid 1px ${color.gold};
  padding: 0.2rem;
  color: ${({ isActive }) => (isActive ? color.white : color.gold)};
  ${({ isActive }) => isActive && `background-color: ${color.gold}`};
  cursor: pointer;
  transition: 0.4s;

  &:hover {
    color: ${color.white};
    background-color: ${color.gold};
  }
`;

const StyledExtraFilter = styled.p`
  color: ${({ isActive }) => (isActive ? color.gold : color.grayedGold)};
  cursor: pointer;
  margin: 0;
  transition: 0.4s;

  &:hover {
    color: ${color.gold};
  }
`;

const ExtraFiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  overflow: hidden;
  max-height: ${({ isActive }) => (isActive ? "500px" : "0")};
  transition: 0.4s;
`;

const PortfolioGalleryFilters = ({
  selectedFilter,
  setSelectedFilter,
  extraFilter,
  setExtraFilter,
}) => {
  return (
    <StyledGalleryFiltersContainer>
      <StyledFilterElement
        onClick={() => setSelectedFilter("slubne")}
        isActive={selectedFilter === "slubne"}
      >
        Ślubne
      </StyledFilterElement>
      <ExtraFiltersWrapper isActive={selectedFilter === "slubne"}>
        <StyledExtraFilter
          onClick={() => setExtraFilter("przygotowania")}
          isActive={extraFilter === "przygotowania"}
        >
          Przygotowania
        </StyledExtraFilter>
        <StyledExtraFilter
          onClick={() => setExtraFilter("sluby")}
          isActive={extraFilter === "sluby"}
        >
          Śluby
        </StyledExtraFilter>
        <StyledExtraFilter
          onClick={() => setExtraFilter("wesela")}
          isActive={extraFilter === "wesela"}
        >
          Wesela
        </StyledExtraFilter>
        <StyledExtraFilter
          onClick={() => setExtraFilter("plener")}
          isActive={extraFilter === "plener"}
        >
          Sesje plenerowe
        </StyledExtraFilter>
      </ExtraFiltersWrapper>
      <StyledFilterElement
        onClick={() => setSelectedFilter("okolicznosciowe")}
        isActive={selectedFilter === "okolicznosciowe"}
      >
        Okolicznościowe
      </StyledFilterElement>
      <StyledFilterElement
        onClick={() => setSelectedFilter("indywidualne")}
        isActive={selectedFilter === "indywidualne"}
      >
        Sesje indywidualne
      </StyledFilterElement>
    </StyledGalleryFiltersContainer>
  );
};

export default PortfolioGalleryFilters;
