export const videos = [
  {
    subtitle: "Marta & Łukasz",
    url: "https://www.youtube.com/embed/0XrNln90Wvc",
  },
  {
    subtitle: "Joanna & Jacek",
    url: "https://www.youtube.com/embed/sU6JByRDcao",
  },
  {
    subtitle: "Joanna & Artur",
    url: "https://www.youtube.com/embed/e4sLklY7I9g",
  },
  {
    subtitle: "Dagmara & Piotr",
    url: "https://www.youtube.com/embed/nrNiv_8iJyw",
  },
  {
    subtitle: "Skrót Angelika & Sławek",
    url: "https://www.youtube.com/embed/H_gktlc8xww",
  },
  {
    subtitle: "Skrót dnia - Oliwia & Andrzej",
    url: "https://www.youtube.com/embed/UGx5J_VyqTw",
  },
  {
    subtitle: "Anna & Bartosz ślub skrót teledysk",
    url: "https://www.youtube.com/embed/P44HGuKit-0",
  },
  {
    title: "Dlaczego warto wybrać drona? Sprawdź jego możliwości",
    url: "https://www.youtube.com/embed/7z3eSB4Cp9s",
  },
];
