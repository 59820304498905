import React, { useEffect, useState } from "react";
import MainLayout from "../layouts/main-layout";
import PageLocation from "../components/page-location";
import styled from "styled-components";
import PortfolioSwitch from "../components/portfolio-switch";
import PortfolioGalleryFilters from "../components/portfolio-gallery-filters";
import YoutubeVideo from "../components/youtube-video";
import { graphql, useStaticQuery } from "gatsby";
import PortfolioGallery from "../components/portfolio-gallery";

const StyledPortfolioContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Portfolio = () => {
  const data = useStaticQuery(
    graphql`
      {
        allFile(filter: { relativeDirectory: { regex: "/portfolio/" } }) {
          edges {
            node {
              relativeDirectory
              name
              childImageSharp {
                fluid(maxWidth: 1920) {
                  src
                  srcSet
                  sizes
                }
              }
            }
          }
        }
      }
    `
  );
  const [activeTab, setActiveTab] = useState("videos");
  const [selectedFilter, setSelectedFilter] = useState("slubne");
  const [extraFilter, setExtraFilter] = useState("przygotowania");

  const [przygotowania, setPrzygotowania] = useState([]);
  const [sluby, setSluby] = useState([]);
  const [wesela, setWesela] = useState([]);
  const [plener, setPlener] = useState([]);
  const [okolicznosciowe, setOkolicznosciowe] = useState([]);
  const [indywidualne, setIndywidualne] = useState([]);
  const [galleryData, setGalleryData] = useState([]);

  useEffect(() => {
    if (data) {
      const przygotowaniaArr = [];
      const slubyArr = [];
      const weselaArr = [];
      const plenerArr = [];
      const okolicznoscioweArr = [];
      const indywidualneArr = [];
      data.allFile.edges.forEach(
        ({
          node: {
            relativeDirectory,
            name,
            childImageSharp: {
              fluid: { src, srcSet, sizes },
            },
          },
        }) => {
          if (relativeDirectory === "portfolio/PRZYGOTOWANIA")
            przygotowaniaArr.push({ name, src, srcSet, sizes });
          if (relativeDirectory === "portfolio/SLUB")
            slubyArr.push({ name, src, srcSet, sizes });
          if (relativeDirectory === "portfolio/WESELE")
            weselaArr.push({ name, src, srcSet, sizes });
          if (relativeDirectory === "portfolio/PLENER")
            plenerArr.push({ name, src, srcSet, sizes });
          if (relativeDirectory === "portfolio/OKOLICZNOSCIOWE")
            okolicznoscioweArr.push({ name, src, srcSet, sizes });
          if (relativeDirectory === "portfolio/INDYWIDUALNE")
            indywidualneArr.push({ name, src, srcSet, sizes });
        }
      );
      setPrzygotowania(przygotowaniaArr);
      setSluby(slubyArr);
      setWesela(weselaArr);
      setPlener(plenerArr);
      setOkolicznosciowe(okolicznoscioweArr);
      setIndywidualne(indywidualneArr);
    }
  }, [data]);

  useEffect(() => {
    if (selectedFilter === "slubne" && extraFilter === "przygotowania")
      setGalleryData(przygotowania);
    else if (selectedFilter === "slubne" && extraFilter === "sluby")
      setGalleryData(sluby);
    else if (selectedFilter === "slubne" && extraFilter === "wesela")
      setGalleryData(wesela);
    else if (selectedFilter === "slubne" && extraFilter === "plener")
      setGalleryData(plener);
    else if (selectedFilter === "okolicznosciowe")
      setGalleryData(okolicznosciowe);
    else if (selectedFilter === "indywidualne") setGalleryData(indywidualne);
    else setGalleryData([]);
  }, [
    selectedFilter,
    extraFilter,
    przygotowania,
    indywidualne,
    okolicznosciowe,
    plener,
    sluby,
    wesela,
  ]);

  return (
    <MainLayout>
      <PageLocation pageName="Portfolio" url="/portfolio" />
      <StyledPortfolioContainer>
        <PortfolioSwitch activeTab={activeTab} setActiveTab={setActiveTab} />
        {activeTab === "gallery" && (
          <PortfolioGalleryFilters
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            extraFilter={extraFilter}
            setExtraFilter={setExtraFilter}
          />
        )}
        {activeTab === "videos" && <YoutubeVideo />}
        {activeTab === "gallery" && <PortfolioGallery images={galleryData} />}
      </StyledPortfolioContainer>
    </MainLayout>
  );
};

export default Portfolio;
