import React from "react";
import styled from "styled-components";
import { color } from "../theme/theme.utils";

const StyledSwitchWrapper = styled.div`
  margin: 16px;
  display: flex;
`;

const StyledSwitchElement = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 35px;
  border: solid 1px ${color.gold};
  padding: 0.2rem;
  color: ${({ isActive }) => (isActive ? color.white : color.gold)};
  ${({ isActive }) => isActive && `background-color: ${color.gold}`};
  cursor: pointer;
  transition: 0.4s;

  &:hover {
    color: ${color.white};
    background-color: ${color.gold};
  }
`;

const PortfolioSwitch = ({ activeTab, setActiveTab }) => {
  return (
    <StyledSwitchWrapper>
      <StyledSwitchElement
        isActive={activeTab === "videos"}
        onClick={() => setActiveTab("videos")}
      >
        Wideo
      </StyledSwitchElement>
      <StyledSwitchElement
        isActive={activeTab === "gallery"}
        onClick={() => setActiveTab("gallery")}
      >
        Zdjęcia
      </StyledSwitchElement>
    </StyledSwitchWrapper>
  );
};
export default PortfolioSwitch;
